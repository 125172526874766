import ReactDOM from "react-dom/client";
import dayjsDuration from "dayjs/plugin/duration";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "./main.less";
import { enableMapSet } from "immer";
import { Toast } from "react-vant";
import App from "./pages/App";
import { subscribeApiErr } from "$/utils/webapi";

enableMapSet();

dayjs.extend(dayjsDuration);
dayjs.extend(utc);
dayjs.extend(timezone);

ReactDOM.createRoot(document.getElementById("root")!).render(<App />);

subscribeApiErr((err) => {
  Toast({ 
    message: err.message,
    position: "top",
  });
})

export default App;
