export const apiErrListeners: ((err: ApiError) => void)[] = [];
export const subscribeApiErr = (callback: (err: ApiError) => void) => {
  apiErrListeners.push(callback);
};

export const webapi = {
  post: async <T>(url: string, params: any, config?: any) => {
    let status = 0;
    let traceId = "";
    let code = 0;
    let msg = "";
    try {
      const resp = await fetch(url, {
        method: "post",
        body: JSON.stringify(params),
        credentials: "include",
        headers: config?.headers,
      });
      status = resp.status;
      traceId = resp.headers.get("eas-trace-id") || "";

      const respData: {
        code: number;
        msg: string;
        data: T;
      } = await resp.json();
      code = respData.code;
      msg = respData.msg;

      if (code) {
        throw new Error(msg || "error code");
      }

      if (status < 200 || status >= 300) {
        throw new Error(msg || "error code");
      }

      return respData.data;
    } catch (e: any) {
      if (!msg) {
        msg = "message" in e ? e.message : e.toString();
      }
      const err = new ApiError(msg, code, traceId, status);
      apiErrListeners.forEach((cb) => cb(err));
      throw err;
    }
  },
};

export class ApiError extends Error {
  code: number;
  traceId: string;
  status: number;
  constructor(msg: string, code: number, traceId: string, status: number) {
    super(msg);
    this.code = code;
    this.traceId = traceId;
    this.status = status;
  }
}
